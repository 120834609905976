@use 'sass:math';

@media screen and (width <= 1024px) {
  .auth-form {
    max-width: 45.4375rem;
  }
}

.auth-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 6.75rem 3.75rem 2.5rem;
}

.auth-form-title {
  font-size: 2rem;
  line-height: 1.364;
  color: #111111;
  font-weight: 700;
  margin-bottom: 1.75rem;
}

.rocket-icon {
  background: url('/assets/images/rocket.svg') no-repeat;
  width: 58.43px;
  height: 56px;
  margin-bottom: 2.625rem;
}

.form-error,
.auth-form-error {
  font-size: 0.875rem;
  line-height: 1.375;
  text-align: left;
  color: #d90125;
  margin-top: 1.25rem;
  word-break: break-word;
}

.login-issues-wrapper {
  border-top: 1px solid #e6e6e6;
  margin-top: 1.125rem;
  padding-top: 1.1875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-issues-title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  color: #333333;
  margin-bottom: 0.4294rem;
}

.login-issue-btn {
  font-size: 0.875rem;
  line-height: 1.357;
  color: var(--primary);
  margin-bottom: 0.8125rem;
}

.login-issue-btn:hover {
  text-decoration: underline;
}

.form-control-forgot-password {
  text-align: right;
  margin-top: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.357;

  .btn-forgot-password {
    color: var(--primary);
  }

  .btn-forgot-password:hover {
    color: var(--secondary);
    text-decoration: underline;
  }
}

.rocket-ui-auth-form-control .form-control-label {
  margin-bottom: 4px;
  font-size: 0.8125rem;
  line-height: 1.364;
  color: #111111;
}

.rocket-ui-auth-form-control .form-control-input:focus {
  border-color: var(--primary);
  outline: none;
}

.rocket-ui-auth-form-control .form-control-input.is-invalid {
  border-color: #d90125;
}

.rocket-ui-auth-form-control .form-control-input.is-invalid:focus {
  border-color: #d90125;
}

.rocket-ui-auth-form-control .form-control-input {
  border: 1px solid #f9f9f9;
  background: #f9f9f9;
  border-radius: 4px;
  padding: 11px 20px;
  height: 44px;
  font-size: 0.9375rem;
  line-height: 1.364;

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px #f9f9f9 inset !important;
    -webkit-text-fill-color: #111111 !important;
  }

  &:-webkit-autofill:focus {
    box-shadow:
      #f9f9f9,
      0 0 0 50px white inset;
    -webkit-text-fill-color: #111111;
  }

  &:-webkit-autofill::first-line,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-family: 'Nunito Sans', sans-serif;
  }
}

.rocket-ui-auth-form-control:not(:first-child) .form-group {
  margin-top: 0.75rem;
}

.auth-form-subtitle {
  font-size: 14px;
  line-height: 1.57;
  color: #4a4a4a;
  margin-bottom: 1.75rem;
}

.back-title {
  display: flex;
  align-items: center;
}

.return-to-sign-in {
  margin-right: 1rem !important;
}

.return-to-sign-in .rkt-icon {
  font-size: 24px !important;
  margin: 0 !important;
  color: var(--text-secondary);

  &:hover {
    color: var(--primary);
  }
}

.reset-password-retry-btn {
  color: var(--primary);
  border: none;
  font-weight: 700;
  font-size: 0.875rem;

  &:hover {
    color: var(--secondary);
  }
}

.auth-form .rkt-form-field .rkt-form-field-link-container {
  position: absolute;
  bottom: -1.875rem;
  right: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.357;
  color: var(--primary);
  cursor: pointer;

  &:hover {
    color: var(--secondary);
  }
}

.rbnk-icon-button-default-theme.rbnk-icon-button-primary:not(:disabled) {
  color: var(--primary) !important;

  &:hover {
    color: var(--primary) !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.auth-layout {
  .logo::after {
    content: 'Operations Portal';
    position: absolute;
    left: 3.5625rem;
    text-align: center;
    bottom: -1.25rem;
    color: #ffffff;
    font-weight: 300;
    font-size: 1.25rem;
  }
}
